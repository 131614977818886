import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from './routes'

import { GlobalStyle } from './styles/global'
import { Normalize } from 'styled-normalize'
import Provider from './context/userContext'

export default function App() {
  return (
    <BrowserRouter>
      <Provider>
        <AppRoutes />
        <GlobalStyle />
      </Provider>
      <Normalize />
    </BrowserRouter>
  )
}
