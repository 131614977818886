import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { Container } from './styles'
import Add from '../../assets/add.svg'
import Edit from '../../assets/edit.svg'
import calendario from '../../assets/calendario.png'
import Api from '../../services/api'
import { UserContext } from '../../context/userContext'
import { Salgado } from '../estoque'

interface ProductInfo {
  name: string
  sabor: string
}

interface Orders {
  id: number
  Product: ProductInfo
  qtd: number
  origem: string
  status: string
}

const initialOrder: Orders = {
  id: 1,
  Product: {
    name: 'Produto inválido',
    sabor: 'Selecione novamente',
  },
  qtd: 1,
  origem: 'Teste',
  status: 'Inicial',
}

export default function Producao() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState('')
  const [estoque, setEstoque] = useState<Salgado[]>([])
  const [ordem, setOrdem] = useState<Orders[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRadio, setSelectedRadio] = useState<string>('criado')
  const [product, setProduct] = useState(0)
  const [qtd, setQtd] = useState(0)
  const [origem, setOrigem] = useState('')
  const [status] = useState('CRIADA')
  const [editStatus, setEditStatus] = useState('CRIADA')
  const [order, setOrder] = useState<Orders>(initialOrder)

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user } = userContext

  const config = {
    headers: {
      Authorization: user.token,
    },
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }
  const today = new Date()

  const getOrder = async () => {
    console.log(user)

    try {
      const { data } = await Api.get('/orders', config)
      // console.log(data)
      setOrdem(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // alert('Você não tem permissão para usar está rota')
      console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const getProducts = async () => {
    try {
      const { data } = await Api.get('/products', config)
      setEstoque(data)
      setIsLoading(false)
    } catch (error) {
      // setIsLoading(false)
      console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  useEffect(() => {
    console.log('use effect orderrs')
    getOrder()
    getProducts()
    setCurrentDate(formatDate(today))
  }, [isAddModalOpen, isEditModalOpen, user])

  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const openEditModal = () => {
    handleRadioClick('')
    if (selectedItemIndex === null) {
      alert('Selecione uma ordem primeiro')
    } else {
      setIsEditModalOpen(true)
    }
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    handleRadioClick('')
  }

  const handleRadioClick = (value: string) => {
    setSelectedRadio(value)
  }

  const getOneOrder = async (orderId: number | null) => {
    console.log('pegando uma ordem')
    const order = `/orders/${orderId}`

    try {
      const { data } = await Api.get(order, config)
      setOrder(data)
      setIsLoading(false)
      console.log(data)
    } catch (error) {
      console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const addOrder = async () => {
    const newOrder = { product, qtd, origem, status }
    setIsLoading(true)
    console.log(newOrder)

    try {
      const { data } = await Api.post('/orders', newOrder, config)
      setIsAddModalOpen(false)
      setIsLoading(false)
      alert('Ordem adicionada com sucesso')
      console.log(data)
    } catch (error) {
      setIsAddModalOpen(false)
      setIsLoading(false)
      alert('Erro ao adicionar ordem')
      console.log(error)
    }

    getOrder()
  }

  const updateOrder = async (orderId: number | null) => {
    const updatedOrder = { status: '' }

    if (editStatus !== '' && editStatus !== '0') {
      updatedOrder.status = editStatus
    }

    // setIsLoading(true)
    try {
      // A rota de update, considerando que você use um ID para identificar o produto
      const updateUrl = `/orders/${orderId}`

      // Realiza a requisição de update
      const { data } = await Api.put(updateUrl, updatedOrder, config)

      // setIsLoading(false)
      alert('Ordem atualizada com sucesso')
      setIsEditModalOpen(false)
      console.log(data)
    } catch (error) {
      // setIsLoading(false)
      alert('Erro ao atualizar ordem')
      setIsEditModalOpen(false)
      // console.log(error)
    }
    getOrder()
    // console.log(updatedProduct)
  }

  return (
    <>
      <Header />
      <Footer />
      <Container>
        <section>
          <h1>Produção</h1>
          <div className='date'>
            <h1>{currentDate}</h1>
            <img src={calendario} alt='calendario' />
          </div>
        </section>
        <section className='orderControl'>
          <button type='button' className='add-order' onClick={openAddModal}>
            <img src={Add} alt='adicionar ordem' />
            <h3>Ordem</h3>
          </button>
          <button type='button' className='edit-order' onClick={openEditModal}>
            <img src={Edit} alt='Editar ordem' />
            <h3>Status</h3>
          </button>
        </section>
        <article className='table'>
          <section className='list-prod'>
            <div className='number'>
              <h2>Nº</h2>
            </div>
            <div className='produto'>
              <h2>Produto</h2>
            </div>
            <div className='qtd'>
              <h2>Qtd</h2>
            </div>
            <div>
              <h2>Origem</h2>
            </div>
            <div className='status'>
              <h2>Status</h2>
            </div>
          </section>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {ordem.map((order) => (
                <section
                  key={order.id}
                  className={`list ${selectedItemIndex === order.id ? 'selected' : ''}`}
                  onClick={() => {
                    getOneOrder(order.id)
                    setSelectedItemIndex((prevIndex) => (prevIndex === order.id ? null : order.id))
                  }}
                >
                  <div className='number'>
                    <h3>{order.id}</h3>
                  </div>
                  <div className='product-div'>
                    <h2 className='nome'>{order.Product.name}</h2>
                    <h4 className='sabor'>{order.Product.sabor}</h4>
                  </div>
                  <div className='qtd'>
                    <h3>{order.qtd}</h3>
                  </div>
                  <div className='origem'>
                    <h3>{order.origem}</h3>
                  </div>
                  <div className='status'>
                    <h3>{order.status}</h3>
                  </div>
                </section>
              ))}
            </>
          )}
        </article>
        {isAddModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Nova Ordem</h1>
              <form action='/processar-formulario' method='post'>
                <label htmlFor='produto'>
                  <select
                    id='produto'
                    name='produto'
                    onChange={(e) => setProduct(Number(e.target.value))}
                  >
                    <option value='null'>Escolha o Salgado</option>
                    {estoque.map((salgado) => (
                      <option
                        value={salgado.id}
                        key={salgado.id}
                      >{`${salgado.name} - ${salgado.sabor}`}</option>
                    ))}
                  </select>
                </label>

                <label htmlFor='origem'>
                  <select id='origem' name='origem' onChange={(e) => setOrigem(e.target.value)}>
                    <option value='Salgado'>Selecione a Origem</option>
                    <option value='REPO-M'>REPO-M</option>
                    <option value='EXTRA'>EXTRA</option>
                    <option value='URGENTE'>URGENTE</option>
                  </select>
                </label>

                <label htmlFor='QUANTIDADE' className='qtd'>
                  <h3>Quantidade</h3>
                  <input
                    type='number'
                    name='qtd'
                    id='qtd'
                    placeholder='Ex: 200'
                    onChange={(e) => setQtd(Number(e.target.value))}
                  />
                </label>
              </form>
              <section className='buttons'>
                <button onClick={addOrder} className='add-button'>
                  <img src={Add} alt='Adicionar' />
                  SIM
                </button>
                <button onClick={closeAddModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isEditModalOpen && (
          <div className='modal'>
            <div className='edit'>
              <h1>Status da Ordem</h1>
              <section>
                <h2>Ordem: Nº {order.id}</h2>
                <h2>Nome: {order.Product.name}</h2>
                <h2>Sabor: {order.Product.sabor}</h2>
                <h2>Quantidade: {order.qtd}</h2>
                <h2>Origem: {order.origem}</h2>
                <h2>Status: {order.status}</h2>
              </section>
              <form action='/processar-formulario' method='post'>
                <label htmlFor='criado' className={selectedRadio === 'CRIADA' ? 'selected' : ''}>
                  <div className='circle'></div>
                  <input
                    type='radio'
                    id='criado'
                    name='status'
                    value='CRIADA'
                    onClick={() => handleRadioClick('CRIADA')}
                    onChange={(e) => setEditStatus(e.target.value)}
                  />
                  Criada
                </label>
                <label htmlFor='prod' className={selectedRadio === 'EM PRODUÇÃO' ? 'selected' : ''}>
                  <div className='circle2'></div>
                  <input
                    type='radio'
                    id='prod'
                    name='status'
                    value='EM PRODUÇÃO'
                    onClick={() => handleRadioClick('EM PRODUÇÃO')}
                    onChange={(e) => setEditStatus(e.target.value)}
                  />
                  Produção
                </label>
                <label htmlFor='cancel' className={selectedRadio === 'CANCELADA' ? 'selected' : ''}>
                  <div className='circle3'></div>
                  <input
                    type='radio'
                    id='cancel'
                    name='status'
                    value='CANCELADA'
                    onClick={() => handleRadioClick('CANCELADA')}
                    onChange={(e) => setEditStatus(e.target.value)}
                  />
                  Cancelada
                </label>
                <label
                  htmlFor='finalizado'
                  className={selectedRadio === 'FINALIZADA' ? 'selected' : ''}
                >
                  <div className='circle4'></div>
                  <input
                    type='radio'
                    id='finalizado'
                    name='status'
                    value='FINALIZADA'
                    onChange={(e) => setEditStatus(e.target.value)}
                    onClick={() => handleRadioClick('FINALIZADA')}
                  />
                  Finalizada
                </label>
              </form>
              <section className='buttons'>
                <button onClick={() => updateOrder(selectedItemIndex)} className='edit-button'>
                  <img src={Edit} alt='EDITAR' />
                  SIM
                </button>
                <button onClick={closeEditModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
      </Container>
    </>
  )
}
