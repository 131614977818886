import styled from 'styled-components'

export const Container = styled.main`
  align-items: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 90%;

    img {
      width: 300px;
    }

    h1 {
      color: #950100;
      font-size: 1.9rem;
      margin: 25px 0;
    }

    label {
      background-color: #fff;
      display: flex;
      border: none;
      border-radius: 5px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      padding: 0.5rem 0.5rem;
      width: 100%;

      img {
        width: 1.3rem;
      }

      input {
        outline: none;
        border: none;
        padding: 0.3rem 0.7rem;
        font-size: 1.1rem;
        width: 100%;
      }
    }

    a {
      width: 100%;
    }

    button {
      background-color: #950100;
      border: none;
      border-radius: 5px;
      color: #fff;
      height: 3rem;
      width: 100%;

      &:hover {
        cursor: pointer;
        background-color: #941716;
      }

      &:active {
        scale: 0.98;
      }
    }
  }
`
