import { Routes, Route } from 'react-router-dom'

import Login from './pages/login'
import Estoque from './pages/estoque'
import Producao from './pages/producao'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/estoque' element={<Estoque />} />
      <Route path='/producao' element={<Producao />} />
    </Routes>
  )
}
