import React from 'react'
import { Container } from './styles'
import { Link, useLocation } from 'react-router-dom'
import Estoq from '../../assets/esoque.svg'
import Prod from '../../assets/prod.svg'

export default function Footer() {
  const location = useLocation()

  return (
    <Container>
      <Link
        to='/estoque'
        className={location.pathname === '/estoque' ? 'btn-selected' : 'btn-estoque'}
      >
        <img src={Estoq} alt='ícone de estoque' />
        <h1>Estoque</h1>
      </Link>

      <Link
        to='/producao'
        className={location.pathname === '/producao' ? 'btn-selected' : 'btn-prod'}
      >
        <img src={Prod} alt='ícone de produção' />
        <h1>Produção</h1>
      </Link>
    </Container>
  )
}
