import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Api from '../../services/api'
import { Container } from './styles'
import Add from '../../assets/add.svg'
import Edit from '../../assets/edit.svg'
import Remove from '../../assets/remove.svg'
import calendario from '../../assets/calendario.png'
import { UserContext } from '../../context/userContext'

export interface Salgado {
  id: number
  name: string
  sabor: string
  qtd: number
  limite: number
  att: number
}

interface Salgado2 {
  name?: string
  sabor?: string
  qtd?: number
  limite?: number
  att?: number
}

const initialProduct: Salgado = {
  id: 0,
  name: 'string',
  sabor: 'string',
  qtd: 0,
  limite: 0,
  att: 0,
}

const secondProduct: Salgado2 = {}

export default function Estoque() {
  const [isLoading, setIsLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState('')
  const [estoque, setEstoque] = useState<Salgado[]>([])
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [sabor, setSabor] = useState('')
  const [qtd, setQtd] = useState(0)
  const [limite, setLimite] = useState(0)
  const [att, setAtt] = useState(0)
  const [editName, setEditName] = useState('')
  const [editSabor, setEditSabor] = useState('')
  const [editQtd, setEditQtd] = useState(0)
  const [editLimite, setEditLimite] = useState(0)
  const [editAtt, setEditAtt] = useState(0)
  const [product, setProduct] = useState<Salgado>(initialProduct)

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { user } = userContext

  const config = {
    headers: {
      Authorization: user.token,
    },
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${day}/${month}/${year}`
  }

  const today = new Date()

  const getProducts = async () => {
    console.log('chamando get')

    try {
      const { data } = await Api.get('/products', config)
      setEstoque(data)
      setIsLoading(false)
      console.log(data)
    } catch (error) {
      console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  const getOneProduct = async (productId: number | null) => {
    console.log('pegando 1 produto')
    const product = `/products/${productId}`

    try {
      const { data } = await Api.get(product, config)
      setProduct(data)
      setIsLoading(false)
      console.log(data)
    } catch (error) {
      console.error('Erro ao obter dados do estoque:', error)
      // Trate o erro conforme necessário, como exibindo uma mensagem de erro para o usuário.
    }
  }

  useEffect(() => {
    console.log('Chamando useEffect')
    getProducts()
    setCurrentDate(formatDate(today))
  }, [user])

  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const openEditModal = () => {
    if (selectedItemIndex === null) {
      alert('Selecione um produto primeiro')
    } else {
      setIsEditModalOpen(true)
    }
    getOneProduct(selectedItemIndex)
    console.log(product)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const openRemoveModal = () => {
    if (selectedItemIndex === null) {
      alert('Selecione um produto primeiro')
    } else {
      setIsRemoveModalOpen(true)
    }
    getOneProduct(selectedItemIndex)
  }

  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false)
  }

  const addProduct = async () => {
    const newProduct = { name, sabor, qtd, limite, att }
    setIsLoading(true)
    try {
      const { data } = await Api.post('/products', newProduct, config)
      setIsAddModalOpen(false)
      setIsLoading(false)
      alert('Produto adicionado com sucesso')
      console.log(data)
    } catch (error) {
      setIsAddModalOpen(false)
      setIsLoading(false)
      alert('Erro ao adicionar o produto')
      console.log(error)
    }
    getProducts()
  }

  const updateProduct = async (productId: number | null) => {
    const updatedProduct: Salgado2 = secondProduct

    if (editName !== '' && editName !== '0') {
      updatedProduct.name = editName
    }

    if (editSabor !== '' && editSabor !== '0') {
      updatedProduct.sabor = editSabor
    }

    if (editQtd !== 0) {
      updatedProduct.qtd = editQtd
    }

    if (editLimite !== 0) {
      updatedProduct.limite = editLimite
    }

    if (editAtt !== 0) {
      updatedProduct.att = editAtt
    }

    // setIsLoading(true)
    try {
      // A rota de update, considerando que você use um ID para identificar o produto
      const updateUrl = `/products/${productId}`

      // Realiza a requisição de update
      const { data } = await Api.put(updateUrl, updatedProduct, config)

      // setIsLoading(false)
      alert('Produto atualizado com sucesso')
      setIsEditModalOpen(false)
      console.log(data)
    } catch (error) {
      // setIsLoading(false)
      alert('Erro ao atualizar o produto')
      setIsEditModalOpen(false)
      // console.log(error)
    }
    getProducts()
    // console.log(updatedProduct)
  }

  const removeProduct = async (productId: number | null) => {
    setIsLoading(true)
    try {
      // A rota de delete, considerando que você use um ID para identificar o produto
      const deleteUrl = `/products/${productId}`

      // Realiza a requisição de delete
      const { data } = await Api.delete(deleteUrl, config)

      setIsLoading(false)
      alert('Produto removido com sucesso')
      setIsRemoveModalOpen(false)
      console.log(data)
    } catch (error) {
      setIsLoading(false)
      alert('Erro ao remover o produto')
      setIsRemoveModalOpen(false)
      console.log(error)
    }
    getProducts()
  }

  return (
    <>
      <Header />
      <Footer />
      <Container>
        <section className='estoque-section'>
          <h1>Estoque</h1>
          <div className='date'>
            <h1>{currentDate}</h1>
            <img src={calendario} alt='calendario' />
          </div>
        </section>
        <section className='tableControl'>
          <button type='button' className='add-btn' onClick={openAddModal}>
            <img src={Add} alt='adicionar produto' />
          </button>
          <button type='button' className='edit-btn' onClick={openEditModal}>
            <img src={Edit} alt='Editar produto' />
          </button>
          <button type='button' className='remove-btn' onClick={openRemoveModal}>
            <img src={Remove} alt='Remover produto' />
          </button>
        </section>
        <article>
          <section className='list-prop'>
            <div id='produto'>
              <h2>Produtos</h2>
            </div>
            <div>
              <h2>Qtd</h2>
              <h2>(atual)</h2>
            </div>
            <div>
              <h2>Limite</h2>
              <h2>(min)</h2>
            </div>
            <div>
              <h2>Gerar</h2>
            </div>
            <div>
              <h2>Att</h2>
            </div>
          </section>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {estoque.map((salgado) => (
                <section
                  key={salgado.id}
                  className={`list ${selectedItemIndex === salgado.id ? 'selected' : ''}`}
                  onClick={() => {
                    getOneProduct(salgado.id)
                    setSelectedItemIndex((prevIndex) =>
                      prevIndex === salgado.id ? null : salgado.id,
                    )
                  }}
                >
                  <div>
                    <h2 className='nome'>{salgado.name}</h2>
                    <h4 className='sabor'>{salgado.sabor}</h4>
                  </div>
                  <div>
                    <h3>{salgado.qtd}</h3>
                  </div>
                  <div>
                    <h3>{salgado.limite}</h3>
                  </div>
                  <div>
                    <h3>{salgado.limite}</h3>
                  </div>
                  <div>
                    <h3>{salgado.att}</h3>
                  </div>
                </section>
              ))}
            </>
          )}
        </article>
        {isAddModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Adicionar Produto</h1>
              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome do Salgado</h2>
                  <input
                    type='text'
                    placeholder='Ex: Coxinha, Pastel'
                    onChange={(e) => setName(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <div>
                  <h2>Sabor</h2>
                  <input
                    type='text'
                    placeholder='Ex: Queijo, Frango'
                    onChange={(e) => setSabor(e.target.value)}
                    minLength={3}
                    required
                  />
                </div>
                <section>
                  <div>
                    <h2>Quantidade</h2>
                    <input
                      type='number'
                      placeholder='Ex: 200'
                      onChange={(e) => setQtd(Number(e.target.value))}
                      required
                    />
                  </div>
                  <div>
                    <h2>Limite</h2>
                    <input
                      type='number'
                      placeholder='Ex: 300'
                      onChange={(e) => setLimite(Number(e.target.value))}
                      required
                    />
                  </div>
                </section>
                <div>
                  <h2>Ordem automática</h2>
                  <input
                    type='number'
                    placeholder='Ex: 1 é a cada 1 dia'
                    onChange={(e) => setAtt(Number(e.target.value))}
                    required
                  />
                </div>
              </form>
              <section className='buttons'>
                <button onClick={addProduct} className='add-button'>
                  <img src={Add} alt='Adicionar' />
                  SIM
                </button>
                <button onClick={closeAddModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isEditModalOpen && (
          <div className='modal'>
            <div className='add-edit'>
              <h1>Editar Produto</h1>
              <form action='/processar-formulario' method='post'>
                <div>
                  <h2>Nome do Salgado</h2>
                  <input
                    type='text'
                    placeholder='Coxinha'
                    onChange={(e) => setEditName(e.target.value)}
                    minLength={3}
                    defaultValue={product.name}
                    required
                  />
                </div>
                <div>
                  <h2>Sabor</h2>
                  <input
                    type='text'
                    placeholder='Queijo'
                    onChange={(e) => setEditSabor(e.target.value)}
                    minLength={3}
                    defaultValue={product.sabor}
                    required
                  />
                </div>
                <section>
                  <div>
                    <h2>Quantidade</h2>
                    <input
                      type='number'
                      placeholder='200'
                      onChange={(e) => setEditQtd(Number(e.target.value))}
                      defaultValue={product.qtd}
                      required
                    />
                  </div>
                  <div>
                    <h2>Limite</h2>
                    <input
                      type='number'
                      placeholder='300'
                      onChange={(e) => setEditLimite(Number(e.target.value))}
                      defaultValue={product.limite}
                      required
                    />
                  </div>
                </section>
                <div>
                  <h2>Ordem automática</h2>
                  <input
                    type='number'
                    placeholder='1'
                    defaultValue={product.att}
                    onChange={(e) => setEditAtt(Number(e.target.value))}
                    required
                  />
                </div>
              </form>
              <section className='buttons'>
                <button onClick={() => updateProduct(selectedItemIndex)} className='edit-button'>
                  <img src={Edit} alt='EDITAR' />
                  SIM
                </button>
                <button onClick={closeEditModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
        {isRemoveModalOpen && (
          <div className='modal'>
            <div className='remove-modal'>
              <section className='info'>
                <h2>Você tem certeza que deseja apagar:</h2>
                <div>
                  <h2>{product.name}</h2>
                  <h3>{product.sabor}</h3>
                </div>
              </section>
              <section className='buttons'>
                <button onClick={() => removeProduct(selectedItemIndex)} className='remove-button'>
                  <img src={Remove} alt='RemoveAR' />
                  SIM
                </button>
                <button onClick={closeRemoveModal}>NÃO</button>
              </section>
            </div>
          </div>
        )}
      </Container>
    </>
  )
}
