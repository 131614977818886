import { Container } from './styles'
import Load from '../../assets/carregando.gif'

export default function Loading() {
  return (
    <Container>
      <img src={Load} alt='carregando' />
    </Container>
  )
}
