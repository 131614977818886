import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  section {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    margin-top: 75px;
    margin-bottom: 15px;
    padding: 0 10px;
    width: 100%;
  }

  h1 {
    font-size: 1%.75;
  }

  h3 {
    margin-left: 10px;
  }

  .date {
    align-items: center;
    display: flex;
    h1 {
      font-size: 1.5rem;
    }
    img {
      margin-left: 5px;
      height: 2rem;
      width: 2rem;
    }
  }

  .orderControl {
    margin-top: 0;
    margin-bottom: 15px;

    button {
      align-items: center;
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      height: 3rem;
      width: 48%;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        scale: 0.95;
      }
    }

    .add-order {
      background-color: #00a859;
    }

    .edit-order {
      background-color: #282829;
    }
  }

  .list-prod {
    background-color: #950100;
    border: 1px solid #950100;
    color: #fff;
    margin: 0;
    padding: 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
    }

    .produto {
      width: 35%;
    }

    .number {
      width: 10%;
    }

    .qtd {
      width: 15%;
    }

    .status {
      width: 20%;
    }

    h2 {
      font-size: 1rem;
    }
  }

  .table {
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 5px solid #950100;
    border-radius: 5px;
    color: #fff;
    width: 95%;
    min-height: 500px;
    margin-bottom: 5rem;

    .list {
      background-color: #fff;
      color: #950100;
      margin: 0;
      height: 3.5rem;
      border-bottom: 1px solid #d3d3d3;
      justify-content: space-between;
      padding: 0;
      width: 100%;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #950100;
        border-right: 1px solid #d3d3d3;
        height: 100%;
        width: 20%;

        h3 {
          margin: 0;
        }
      }

      .number {
        width: 10%;
      }

      .qtd {
        width: 15%;
      }

      .origem {
      }

      .product-div {
        flex-direction: column;
        width: 35%;

        h2 {
          font-size: 1.2rem;
        }

        h4 {
          color: #333;
          font-size: 0.8rem;
        }
      }

      .status {
        h3 {
          font-size: 0.9rem;
        }
      }
    }
  }

  .selected {
    background-color: #950100;

    div {
      background-color: #950100;

      h2,
      h3,
      h4 {
        color: #fff !important;
      }

      border-right: 0px !important;
    }
  }

  /* Modais */

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Cor de fundo com transparência */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Z-index alto para sobrepor o conteúdo principal */

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      height: 80%;
      background-color: #fff; /* Fundo branco */
      color: #000; /* Cor do texto preta */
      padding: 20px;
      border-radius: 15px;
    }
  }

  .add-edit {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    h1 {
      text-align: center;
      margin: 5px 0;
    }

    form {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        width: 100%;
        margin-bottom: 5px;

        select {
          height: 3rem;
          border-radius: 5px;
          background-color: #fff;
          color: black;
          border: black 2px solid;
          font-weight: bold;
          padding: 0 10px;
          font-size: 1.5rem;
          margin: 10px 0;
          width: 100%;
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        }
      }

      .qtd {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
          font-size: 1.5rem;
        }
      }

      input {
        border-radius: 5px;
        padding: 0 10px;
        margin-bottom: 0;
        height: 3rem;
        border: none;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        width: 40%;

        &:focus {
          border: 1px solid #950100;
        }
      }

      input:focus {
        outline: none; /* Remove a borda padrão do navegador */
        border: 3px solid #333;
      }
    }
  }

  .edit {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    h1 {
      text-align: center;
      margin: 5px 0;
    }

    section {
      margin: 0;
      padding: 0;
      display: flex;
      height: 12rem;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      h2 {
        margin-bottom: 5px;
      }
    }

    form {
      width: 100%;
      display: flex;
      height: 8rem;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 48%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.2rem;
        background-color: #fff;
        border: 3px solid #282829;

        div {
          width: 1.2rem;
          height: 1.2rem;
          padding: 0;
          margin-right: 5px;
          background-color: #00a859;
          border-radius: 50%; /* Define o raio de 50% para torná-lo um círculo */
        }

        .circle2 {
          background-color: #f58634;
        }

        .circle3 {
          background-color: #ed3237;
        }

        .circle4 {
          background-color: black;
        }
      }

      input {
        border-radius: 5px;
        padding: 0 10px;
        display: none;
        margin-bottom: 0;
        height: 3rem;
        border: none;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        width: 40%;

        &:focus {
          border: 1px solid #950100;
        }
      }

      input:focus {
        outline: none; /* Remove a borda padrão do navegador */
        border: 3px solid #282829;
      }

      /* Estilo para botões de rádio selecionados */
      .selected {
        background-color: #282829;
        color: #fff;

        input {
          display: none;
        }
      }
    }
  }

  .buttons {
    height: 4.4rem !important;
    display: flex;
    flex-direction: row !important;
    margin-top: 0;
    padding: 0;
    button {
      color: #fff;
      height: 3.5rem;
      border: none;
      border-radius: 5px;
      background-color: #333;
      font-size: 1.5rem;
      font-weight: bold;
      width: 48%;

      img {
        margin-right: 10px;
        width: 1.6rem;
      }
    }

    .add-button {
      background-color: #00a859;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .edit-button {
      background-color: #f58634;
    }

    .remove-button {
      background-color: #ed3237;
    }
  }
`
