import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 50vh;
  width: 100%;
  img {
    margin: 20px;
    width: 4rem;
  }
`
