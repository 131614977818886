import styled from 'styled-components'

export const Container = styled.footer`
  align-items: center;
  background-color: #950100;
  display: flex;
  justify-content: space-around;
  height: 4rem;
  color: #fff;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 998;

  a {
    align-items: center;
    border: none;
    border-radius: 2px;
    background-color: #950100;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 90%;
    width: 49%;

    img {
      margin-right: 10px;
      width: 1rem;
    }

    h1 {
      font-size: 1rem;
    }
  }

  .btn-selected {
    background-color: #d1600f;
  }
`
