import React, { useState } from 'react'
import { Container } from './styles'
import Logo2 from '../../assets/logo-secundaria.svg'
import MenuIcon from '../../assets/menu.svg'
import Logo from '../../assets/logo-principal.svg'
import Deslogar from '../../assets/deslogar.svg'
// import Password from '../../assets/password.svg'
import Edit from '../../assets/edit2.svg'
// import Relogio from '../../assets/relogio.svg'
import { useNavigate } from 'react-router-dom'

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [hourOpen, setHourOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const navigate = useNavigate()

  const SignOut = () => {
    navigate('/')
  }

  const openLogoutModal = () => {
    setLogoutOpen(true)
  }

  const closeLogoutModal = () => {
    setLogoutOpen(false)
  }

  const openHourModal = () => {
    setHourOpen(true)
  }

  const closeHourModal = () => {
    setHourOpen(false)
  }

  return (
    <Container>
      <img src={Logo2} alt='Lourdes Salgados Congelados' />
      <img src={MenuIcon} alt='Abrir menu' onClick={toggleMenu} className='menu' />
      <div className={`menu-container ${isMenuOpen ? 'open' : ''}`}>
        {/* Conteúdo do menu */}
        <h2 onClick={toggleMenu}>x</h2>
        <img src={Logo} alt='Lourdes Salgados Congelados' />
        <h1>Olá, Admin</h1>
        <div onClick={openLogoutModal}>
          <img src={Deslogar} alt='Deslogar' />
          <h3>Sair</h3>
        </div>
        {/* <div>
          <img src={Password} alt='Deslogar' />
          <h3>Trocar senha</h3>
        </div> */}
        <div onClick={openHourModal}>
          <img src={Edit} alt='Editar Ordem' />
          <h3>Ordem Diária - 00h00</h3>
        </div>
        {/* <div>
          <img src={Relogio} alt='Editar Ordem' />
          <h3>Histórico de Ordens</h3>
        </div> */}
      </div>
      {logoutOpen && (
        <div className='modal'>
          <section>
            <h1>Você deseja sair?</h1>
            <div className='buttons'>
              <button onClick={SignOut} className='sim'>
                SIM
              </button>
              <button onClick={closeLogoutModal}>NÃO</button>
            </div>
          </section>
        </div>
      )}

      {hourOpen && (
        <div className='modal'>
          <section className='modal2'>
            <h1>Ordem Diária</h1>
            <label htmlFor='horaInput'>
              <h2>Digite a hora:</h2>
              <input type='time' id='horaInput' name='horaInput'></input>
            </label>

            <div className='buttons'>
              <button onClick={closeHourModal} className='sim'>
                SIM
              </button>
              <button onClick={closeHourModal}>NÃO</button>
            </div>
          </section>
        </div>
      )}
    </Container>
  )
}
