import React, { FormEvent, useContext, useState } from 'react'
import Loading from '../../components/Loading'
import { useNavigate } from 'react-router-dom'
import { Container } from './styles'
import Logo from '../../assets/logo-principal.svg'
import Password from '../../assets/password.svg'
import Perfil from '../../assets/perfil.svg'
import Api from '../../services/api'
import { UserContext } from '../../context/userContext'

export default function Login() {
  const [username, setUsername] = useState('')
  const [pass, setPass] = useState('')
  const [emailFocus, setEmailFocus] = useState(false)
  const [senhaFocus, setSenhaFocus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const userContext = useContext(UserContext)

  // Verifica se o contexto de usuário está definido antes de acessar suas propriedades
  if (!userContext) {
    throw new Error('UserContext is undefined')
  }

  const { updateUser } = userContext

  const handleEmailFocus = () => {
    setEmailFocus(true)
  }

  const handleEmailBlur = () => {
    setEmailFocus(false)
  }

  const handleSenhaFocus = () => {
    setSenhaFocus(true)
  }

  const handleSenhaBlur = () => {
    setSenhaFocus(false)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const user = { username, pass }
    console.log(user)

    setIsLoading(true)
    try {
      const { data } = await Api.post('/', user)
      updateUser(data)
      setIsLoading(false)
      navigate('/estoque')
      console.log(data)
    } catch (error) {
      setIsLoading(false)
      alert('Verifique seu nome de usuário ou senha estão corretos')
      console.log(error)
    }
  }

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit}>
          <img src={Logo} alt='logo lourdes alimentos congelados' />
          <h1>Gestão de Estoque</h1>
          <label htmlFor='email' style={{ border: emailFocus ? '3px solid #950100' : 'none' }}>
            <img src={Perfil} alt='cadeado' />
            <input
              id='email'
              name='email'
              type='text'
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              placeholder='Insira seu nome de usuário'
              onChange={(e) => setUsername(e.target.value)}
              minLength={3}
              required
            />
          </label>
          <label htmlFor='senha' style={{ border: senhaFocus ? '3px solid #950100' : 'none' }}>
            <img src={Password} alt='cadeado' />
            <input
              id='senha'
              name='senha'
              type='password'
              onFocus={handleSenhaFocus}
              onBlur={handleSenhaBlur}
              placeholder='Insira a sua senha'
              onChange={(e) => setPass(e.target.value)}
              minLength={3}
              required
            />
          </label>

          <button type='submit'>Entrar</button>
          {/* onClick={handleEntrarClick} */}
        </form>
      )}
    </Container>
  )
}
